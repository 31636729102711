<template>
  <div class="login">
    <div class="login_content fl-fd-jcc-aic">
      <div class="header">
        <a href="/">
          <img src="../../assets/images/logo1.png" class="logo" alt="logo" />
          <span class="title">智慧乡镇大数据平台</span>
        </a>
      </div>
      <div class="login_content_box fl-fd-aic-jcc mt-40">
        <div class="login_content_box_title fs-20 fl">账号密码登录</div>
        <div class="login_content_box_input">
          <div class="mt-30 w-440 h-64">
            <form>
              <el-input
                v-model="ruleForm.account"
                placeholder="账号"
                prefix-icon="el-icon-user"
              ></el-input>
            </form>
          </div>

          <div class="login_content_box_password mt-20">
            <form>
              <el-input
                type="password"
                v-model="ruleForm.password"
                prefix-icon="el-icon-lock"
                placeholder="登录密码"
                show-password
              ></el-input>
            </form>
          </div>
          <div class="mt-20 cup fl-jcfe-aic" @click="remPasswork">
            <img
              class="wh-19"
              :src="
                checked
                  ? require('../../assets/images/select_box_true.png')
                  : require('../../assets/images/select_box.png')
              "
              alt=""
            />
            <span class="fs-14 co-#1890ff ml-5">记住密码</span>
          </div>
          <div
            class="w-440 h-63 co-ffffff fs-24 fl mt-40 borr-6 cup"
            style="background-color: #1890ff"
            @click="loginBtn"
          >
            登录
          </div>
        </div>
      </div>
      <div class="login_content_base">
        <div>
          <span style="margin-right: 20px"
            >Copyright © 2023 广州同立信息科技有限公司 版权所有</span
          >
          <a
            href="https://beian.miit.gov.cn"
            style="color: black"
            target="_blank"
            >粤ICP备2022071653号</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { setCookie, getCookie, clearCookie } from "../../assets/js/cookie";
import {
  showFullScreenLoading,
  hideFullScreenLoading,
} from "../../assets/js/loading";
export default {
  data() {
    return {
      httpName: "http://106.55.36.38:5000",
      loginData: [],
      checked: true,
      loginShow: true,
      ruleForm: {
        account: "",
        password: "",
      },
    };
  },

  mounted() {
    this.ruleForm = getCookie(this.ruleForm);
    // this.getloginData();
  },
  methods: {
    //获取登录页面详情数据
    getloginData() {
      showFullScreenLoading(".login");
      this.api.apiLoginDetail().then((res) => {
        console.log(res, "详情数据");
        this.loginData = res.data;

        hideFullScreenLoading();
      });
    },
    loginBtn() {
      //保存的账号
      var name = this.ruleForm.account;
      //保存的密码
      var pass = this.ruleForm.password;
      if (!this.ruleForm.account || !this.ruleForm.password) {
        this.$message.error("请输入正确的账号或密码");
        return;
      }
      //判断复选框是否被勾选 勾选则调用配置cookie方法
      if (this.checked) {
        //传入账号名，密码，和保存天数3个参数
        setCookie(name, pass, 7);
      } else {
        //清除cookie
        clearCookie();
      }
      this.getLogin();
    },
    getLogin() {
      this.api.apiLogin(JSON.stringify(this.ruleForm)).then((res) => {
        localStorage.setItem("token", JSON.stringify(res.data));
        setCookie(this.ruleForm.account, this.ruleForm.password, 7, res.data);
        this.loginList = res.data;
        console.log(res.code, "状态码");
        if (res.code == 200) {
          this.$message.success("登录成功");
          this.$router.push({ name: "index", params: { id: 1 } });
        } else {
          this.$message.error("用户名或密码不正确");
        }
      });
    },
    remPasswork() {
      this.checked = !this.checked;
    },
    //回车键登录
    handleToLoginKeyDown($event) {
      if ($event.keyCode === 13) {
        this.loginBtn();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  min-height: 100%;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='800' height='800' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='88' y1='88' x2='0' y2='0'%3E%3Cstop offset='0' stop-color='%23064e77'/%3E%3Cstop offset='1' stop-color='%230a7dbe'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='75' y1='76' x2='168' y2='160'%3E%3Cstop offset='0' stop-color='%238f8f8f'/%3E%3Cstop offset='0.09' stop-color='%23b3b3b3'/%3E%3Cstop offset='0.18' stop-color='%23c9c9c9'/%3E%3Cstop offset='0.31' stop-color='%23dbdbdb'/%3E%3Cstop offset='0.44' stop-color='%23e8e8e8'/%3E%3Cstop offset='0.59' stop-color='%23f2f2f2'/%3E%3Cstop offset='0.75' stop-color='%23fafafa'/%3E%3Cstop offset='1' stop-color='%23FFFFFF'/%3E%3C/linearGradient%3E%3Cfilter id='c' x='0' y='0' width='200%25' height='200%25'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='12' /%3E%3C/filter%3E%3C/defs%3E%3Cpolygon fill='url(%23a)' points='0 174 0 0 174 0'/%3E%3Cpath fill='%23000' fill-opacity='.5' filter='url(%23c)' d='M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z'/%3E%3Cpath fill='url(%23b)' d='M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z'/%3E%3C/svg%3E");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: top left;
  padding: 11.25rem;
  position: relative;
  .login_content_title {
    width: 36.25rem;
    height: 7rem;
    background-image: url("../../assets/images/login_font.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .login_content_box {
    width: 32.75rem;
    height: 26rem;
    color: #1890ff;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 2.5rem;
    box-sizing: border-box;
  }
  .login_content_base {
    position: fixed;
    text-align: center;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .login_bottom {
    width: 100%;
    height: 5rem;
    background: #292f38;
  }
  .header {
    height: 44px;
    line-height: 44px;

    .badge {
      position: absolute;
      display: inline-block;
      line-height: 1;
      vertical-align: middle;
      margin-left: -12px;
      margin-top: -10px;
      opacity: 0.8;
    }

    .logo {
      height: 44px;
      vertical-align: top;
      margin-right: 16px;
      border-style: none;
    }

    .title {
      font-size: 40px;
      color: rgba(0, 0, 0, 0.85);
      font-family: Avenir, "Helvetica Neue", Arial, Helvetica, sans-serif;
      font-weight: 600;
      position: relative;
      top: 2px;
    }
  }
}

/deep/ .el-input__inner {
  width: 27.75rem;
  height: 3rem;
  color: #bfbfbf;
  // border: 0.0625rem solid #00e4ff;
  // background: rgba(0, 228, 255, 0.1);
}

/deep/.el-input__icon {
  margin-left: 0.625rem;
  font-size: 24px;
}

/deep/.el-input__inner {
  color: #000 !important;
  padding: 0 0 0 3.125rem;
  font-size: 1.125rem;
}

/deep/input::-webkit-input-placeholder {
  color: #bfbfbf;
  font-size: 1.125rem;
}
/deep/input::-moz-input-placeholder {
  color: #bfbfbf;
  font-size: 1.125rem;
}
/deep/input::-ms-input-placeholder {
  color: #bfbfbf;
  font-size: 1.125rem;
}
</style>
