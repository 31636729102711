//设置cookie
export function setCookie(c_name, c_pwd, exdays) {
  var exdate = new Date(); //获取时间
  exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
  //字符串拼接cookie
  window.document.cookie =
    "userName" + "=" + c_name + ";path=/;expires=" + exdate.toGMTString();
  window.document.cookie =
    "userPwd" + "=" + c_pwd + ";path=/;expires=" + exdate.toGMTString();
}

/* 获取cookie */
export function getCookie(c_name) {
  if (document.cookie.length > 0) {
    var arr = document.cookie.split("; "); //这里显示的格式需要切割一下自己可输出看下
    for (var i = 0; i < arr.length; i++) {
      var arr2 = arr[i].split("="); //再次切割
      //判断查找相对应的值
      if (arr2[0] == "userName") {
        c_name.account = arr2[1]; //保存到保存数据的地方
      } else if (arr2[0] == "userPwd") {
        c_name.password = arr2[1];
      }
    }
  }
  return c_name;
}

/* 删除cookie */
export function clearCookie(c_name) {
  setCookie(c_name, "", -1);
}
